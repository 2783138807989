<template>
  <div>
    <repayment-inquiry-popup-reg-num
      :temp-search-reg-num="tempSearchRegNum"
      :is-popup-reg-num-active.sync="isPopupRegNumActive"
      @select-item="fncSelectRegNum"
    />
    <repayment-inquiry-popup-detail
      :data-popup-detail="dataPopupDetail"
      :is-popup-detail-active.sync="isPopupDetailActive"
    />
    <b-card title="ประวัติการรับชำระ">
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="inputSearchRegNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค้นหาเลขทะเบียน <span class="text-danger">*</span>
              </div>
            </template>
            <b-input-group v-show="!regNum1 && !regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="inputSearchRegNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                @keydown.enter.native="refInputSearchRegNum2.focus()"
              />
              <b-form-input
                id="inputSearchRegNum2"
                ref="refInputSearchRegNum2"
                v-model="inputSearchRegNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;  margin-left: 5px;"
                @keydown.enter.native="fncSearcRegNum(inputSearchRegNum1, inputSearchRegNum2)"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupRegNum()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="regNum1 || regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="regNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                disabled
              />
              <b-form-input
                id="inputSearchRegNu2"
                v-model="regNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%; margin-left: 5px;"
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelRegNum()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="1"
            label-cols-sm="12"
          >
            <b-button
              variant="primary"
              @click="fncSubmit()"
            >
              <feather-icon icon="SearchIcon" /> ค้นหาข้อมูล
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อลูกค้า"
            label-for="customerName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อลูกค้า
              </div>
            </template>
            <b-form-input
              id="customerName"
              :value="customerName"
              placeholder="ชื่อลูกค้า"
              trim
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขตัวถัง"
            label-for="chassisNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขตัวถัง
              </div>
            </template>
            <b-form-input
              id="chassisNum"
              :value="chassisNum"
              placeholder="หมายเลขตัวถัง"
              trim
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เลขที่สัญญา"
            label-for="conNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขที่สัญญา
              </div>
            </template>
            <b-form-input
              id="conNum"
              :value="conNum"
              placeholder="เลขที่สัญญา"
              trim
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="พอร์ต"
            label-for="branchCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-form-input
              id="branchCode"
              :value="branchCode"
              placeholder="พอร์ต"
              trim
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อพอร์ต"
            label-for="branchName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อพอร์ต
              </div>
            </template>
            <b-form-input
              id="branchName"
              :value="branchName"
              placeholder="ชื่อพอร์ต"
              trim
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="สหกรณ์"
            label-for="cooperateName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สหกรณ์
              </div>
            </template>
            <b-form-input
              id="cooperateName"
              :value="cooperateName"
              placeholder="สหกรณ์"
              trim
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เซลล์แมน"
            label-for="salesMan"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เซลล์แมน
              </div>
            </template>
            <b-form-input
              id="salesMan"
              :value="salesMan"
              placeholder="เซลล์แมน"
              trim
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="ข้อมูลสรุป">
      <b-row class="delete-margin-report-summary">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="สรุป ณ วันที่"
            label-for="inputSearchRegNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สรุป ณ วันที่
              </div>
            </template>
            <b-form-input
              id="accesstime"
              :value="`${resolveFormatDate(accesstime)}`"
              placeholder="สรุป ณ วันที่"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-table-simple
            caption-top
            bordered
            class="position-relative mb-1"
          >
            <b-tbody>
              <b-tr>
                <b-td>
                  <b>ยอดเช่ารวม</b>
                </b-td>
                <b-td
                  class="text-right"
                  style="width: 20%;"
                >
                  {{ Number(rentTotalAmt).toLocaleString() }} บาท
                </b-td>
                <b-td
                  class="text-right"
                  style="width: 20%;"
                >
                  {{ Number(rentTotalDay).toLocaleString() }} วัน
                </b-td>
                <b-td
                  class="text-right"
                  style="width: 20%;"
                />
              </b-tr>
              <b-tr>
                <b-td>
                  <b>ยอดคงเหลือ</b>
                </b-td>
                <b-td class="text-right">
                  {{ Number(outstandingAmt).toLocaleString() }} บาท
                </b-td>
                <b-td class="text-right">
                  {{ Number(outstandingDay).toLocaleString() }} วัน
                </b-td>
                <b-td class="text-right">
                  {{ Number(outstandingPercent).toLocaleString() }} %
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <b>ยอดชำระแล้ว</b>
                </b-td>
                <b-td class="text-right">
                  {{ Number(paidAmt).toLocaleString() }} บาท
                </b-td>
                <b-td class="text-right">
                  {{ Number(paidDay).toLocaleString() }} วัน
                </b-td>
                <b-td class="text-right">
                  {{ Number(paidPercent).toLocaleString() }} %
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <b>ยอดค้างชำระ</b>
                </b-td>
                <b-td class="text-right">
                  {{ Number(overdueAmt).toLocaleString() }} บาท
                </b-td>
                <b-td class="text-right">
                  {{ Number(overdueDay).toLocaleString() }} วัน
                </b-td>
                <b-td class="text-right">
                  {{ Number(overduePercent).toLocaleString() }} %
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <b>ยอดยังไม่ถึงกำหนด</b>
                </b-td>
                <b-td class="text-right">
                  {{ Number(futureAmt).toLocaleString() }} บาท
                </b-td>
                <b-td class="text-right">
                  {{ Number(futureDay).toLocaleString() }} วัน
                </b-td>
                <b-td class="text-right">
                  {{ Number(futurePercent).toLocaleString() }} %
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <b>ยอด Drop</b>
                </b-td>
                <b-td class="text-right">
                  {{ Number(dropAmt).toLocaleString() }} บาท
                </b-td>
                <b-td class="text-right">
                  {{ Number(dropDay).toLocaleString() }} วัน
                </b-td>
                <b-td class="text-right">
                  {{ Number(dropPercent).toLocaleString() }} %
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="dataRentalTable.length"
      title="ข้อมูลรายละเอียด"
    >
      <b-row>
        <b-col cols="12">
          <b-table-simple
            caption-top
            bordered
            class="position-relative mb-1"
          >
            <b-tbody>
              <b-tr
                v-for="(item, index) in dataRentalTable"
                :key="index"
              >
                <b-td>
                  <b>ปี {{ item.rent_year }}</b>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_01">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[1] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 1)"
                    >{{ item.rent_amt_month_01 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_02">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[2] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 2)"
                    >{{ item.rent_amt_month_02 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_03">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[3] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 3)"
                    >{{ item.rent_amt_month_03 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_04">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[4] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 4)"
                    >{{ item.rent_amt_month_04 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_05">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[5] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 5)"
                    >{{ item.rent_amt_month_05 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_06">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[6] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 6)"
                    >{{ item.rent_amt_month_06 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_07">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[7] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 7)"
                    >{{ item.rent_amt_month_07 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_08">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[8] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 8)"
                    >{{ item.rent_amt_month_08 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_09">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[9] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 9)"
                    >{{ item.rent_amt_month_09 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_10">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[10] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 10)"
                    >{{ item.rent_amt_month_10 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_11">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[11] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 11)"
                    >{{ item.rent_amt_month_11 }}</span>
                  </template>
                </b-td>
                <b-td
                  class="text-center"
                  style="width: 7%"
                >
                  <template v-if="item.rent_amt_month_12">
                    <p style="margin-bottom: 5px;">
                      {{ resolveMonth[12] }}
                    </p>
                    <span
                      class="cursor-pointer text-primary"
                      @click="fncPopupDetail(item.rent_year, 12)"
                    >{{ item.rent_amt_month_12 }}</span>
                  </template>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BTableSimple, BTbody, BTr, BTd,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import store from '@/store'
import RepaymentInquiryPopupRegNum from './repayment-inquiry-popup/RepaymentInquiryPopupRegNum.vue'
import RepaymentInquiryPopupDetail from './repayment-inquiry-popup/RepaymentInquiryPopupDetail.vue'
import RepaymentInquiryStoreModule from './RepaymentInquiryStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    RepaymentInquiryPopupRegNum,
    RepaymentInquiryPopupDetail,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appRepaymentInquiry'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, RepaymentInquiryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // ประกาศตัวแปร Ref
    const refInputSearchRegNum2 = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearchRegNum1 = ref(null)
    const inputSearchRegNum2 = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const regNum1 = ref(null)
    const regNum2 = ref(null)
    const tempSearchRegNum = ref(null)
    const customerName = ref(null)
    const chassisNum = ref(null)
    const conNum = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const cooperateName = ref(null)
    const salesMan = ref(null)
    const accesstime = new Date()
    const rentTotalAmt = ref(null)
    const rentTotalDay = ref(null)
    const outstandingAmt = ref(null)
    const outstandingDay = ref(null)
    const outstandingPercent = ref(null)
    const paidAmt = ref(null)
    const paidDay = ref(null)
    const paidPercent = ref(null)
    const overdueAmt = ref(null)
    const overdueDay = ref(null)
    const overduePercent = ref(null)
    const futureAmt = ref(null)
    const futureDay = ref(null)
    const futurePercent = ref(null)
    const dropAmt = ref(null)
    const dropDay = ref(null)
    const dropPercent = ref(null)
    const dataRentalTable = ref([])
    const dataPopupDetail = ref([])

    // ประกาศตัวแปร Active / Inactive
    const isPopupRegNumActive = ref(false)
    const isPopupDetailActive = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นเปิด Popup เลขทะเบียน */
    const fncOpenPopupRegNum = search => {
      if (search) tempSearchRegNum.value = search
      else tempSearchRegNum.value = null

      isPopupRegNumActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเลขทะเบียน */
    const fncSearcRegNum = (val1, val2) => {
      const payload = {
        action: 'LIST',
        events: 'CONTRACT',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        TEXT_SEARCH: '',
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_REG`, payload)
        .then(response => {
          const getSearchResult = response.data.responseData.filter(
            item => item.regnum1.toLowerCase().indexOf(val1) > -1 && item.regnum2.toLowerCase().indexOf(val2) > -1,
          )

          if (getSearchResult.length === 1) {
            regNum1.value = getSearchResult[0].regnum1
            regNum2.value = getSearchResult[0].regnum2
          } else if ((getSearchResult.length > 1)) {
            fncOpenPopupRegNum(`${val1}-${val2}`)
          }
        })
    }

    /* ฟังก์ชั่นยกเลิกเลขทะเบียน */
    const fncCancelRegNum = () => {
      regNum1.value = null
      regNum2.value = null
      inputSearchRegNum1.value = null
      inputSearchRegNum2.value = null

      customerName.value = null
      chassisNum.value = null
      conNum.value = null
      branchCode.value = null
      branchName.value = null
      cooperateName.value = null
      salesMan.value = null

      rentTotalAmt.value = null
      rentTotalDay.value = null
      outstandingAmt.value = null
      outstandingDay.value = null
      outstandingPercent.value = null
      paidAmt.value = null
      paidDay.value = null
      paidPercent.value = null
      overdueAmt.value = null
      overdueDay.value = null
      overduePercent.value = null
      futureAmt.value = null
      futureDay.value = null
      futurePercent.value = null
      dropAmt.value = null
      dropDay.value = null
      dropPercent.value = null
      dataRentalTable.value = []
      dataPopupDetail.value = []
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเลขทะเบียนจาก Popup */
    const fncSelectRegNum = data => {
      regNum1.value = data.regnum1
      regNum2.value = data.regnum2
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยืนยัน (ค้นหา) */
    const fncSubmit = () => {
      const payloadContactInq = {
        action: 'VIEW',
        events: 'INQUIRY',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        regnum1_in: regNum1.value,
        regnum2_in: regNum2.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CONTRACT_INQ`, payloadContactInq)
        .then(response => {
          if (response.data.responseData.length) {
            chassisNum.value = response.data.responseData[0].chassis_number
            conNum.value = response.data.responseData[0].connum
            branchCode.value = response.data.responseData[0].cpnbrncod
            branchName.value = response.data.responseData[0].cpnbrn_desc
            cooperateName.value = response.data.responseData[0].cooperate_code
            salesMan.value = response.data.responseData[0].sales
          } else {
            // customerName.value = null
            chassisNum.value = null
            conNum.value = null
            branchCode.value = null
            branchName.value = null
            cooperateName.value = null
            salesMan.value = null
          }

          inputSearchRegNum1.value = null
          inputSearchRegNum2.value = null
        })
      const payloadRentalInq1 = {
        action: 'VIEW',
        events: 'INQUIRY',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        current_date: moment(accesstime.value).format('YYYY-MM-DD'),
        regnum1_in: regNum1.value,
        regnum2_in: regNum2.value,
        chassis_number: chassisNum.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_RENTAL_INQUIRY_1`, payloadRentalInq1)
        .then(response => {
          if (response.data.responseData.length) {
            console.log('datacus', response.data.responseData)
            customerName.value = response.data.responseData[0].customer_name
            rentTotalAmt.value = response.data.responseData[0].rent_total_amt
            rentTotalDay.value = response.data.responseData[0].rent_total_day
            outstandingAmt.value = response.data.responseData[0].outstanding_amt
            outstandingDay.value = response.data.responseData[0].outstanding_day
            outstandingPercent.value = response.data.responseData[0].outstanding_percent
            paidAmt.value = response.data.responseData[0].paid_amt
            paidDay.value = response.data.responseData[0].paid_day
            paidPercent.value = response.data.responseData[0].paid_percent
            overdueAmt.value = response.data.responseData[0].overdue_amt
            overdueDay.value = response.data.responseData[0].overdue_day
            overduePercent.value = response.data.responseData[0].overdue_percent
            futureAmt.value = response.data.responseData[0].future_amt
            futureDay.value = response.data.responseData[0].future_day
            futurePercent.value = response.data.responseData[0].future_percent
            dropAmt.value = response.data.responseData[0].drop_amt
            dropDay.value = response.data.responseData[0].drop_day
            dropPercent.value = response.data.responseData[0].drop_percent
          } else {
            rentTotalAmt.value = null
            rentTotalDay.value = null
            outstandingAmt.value = null
            outstandingDay.value = null
            outstandingPercent.value = null
            paidAmt.value = null
            paidDay.value = null
            paidPercent.value = null
            overdueAmt.value = null
            overdueDay.value = null
            overduePercent.value = null
            futureAmt.value = null
            futureDay.value = null
            futurePercent.value = null
            dropAmt.value = null
            dropDay.value = null
            dropPercent.value = null
          }
        })

      const payloadRentalInq2 = {
        action: 'LIST',
        events: 'INQUIRY',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        current_date: moment(accesstime.value).format('YYYY-MM-DD'),
        regnum1_in: regNum1.value,
        regnum2_in: regNum2.value,
        chassis_number: chassisNum.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_RENTAL_INQUIRY_2`, payloadRentalInq2)
        .then(response => {
          dataRentalTable.value = response.data.responseData
        })
    }

    /* ฟังก์ชั่นเมื่อคลิกดูรายละเอียดของแต่ละเดือน */
    const fncPopupDetail = (year, month) => {
      dataPopupDetail.value = {
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        accesstime: moment(accesstime.value).format('YYYY-MM-DD'),
        regNum1: regNum1.value,
        regNum2: regNum2.value,
        chassisNum: chassisNum.value,
        rentYear: year,
        rentMonth: month,
        customerName: customerName.value,
        conNum: conNum.value,
        branchCode: branchCode.value,
        branchName: branchName.value,
        cooperateName: cooperateName.value,
        salesMan: salesMan.value,
      }

      isPopupDetailActive.value = true
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)

    const resolveMonth = [
      '',
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ]
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {
      // ประกาศตัวแปร Ref
      refInputSearchRegNum2,

      // ประกาศตัวแปร Textbox
      inputSearchRegNum1,
      inputSearchRegNum2,

      // ประกาศตัวแปรสำหรับเก็บค่า
      regNum1,
      regNum2,
      tempSearchRegNum,
      customerName,
      chassisNum,
      conNum,
      branchCode,
      branchName,
      cooperateName,
      salesMan,
      accesstime,
      rentTotalAmt,
      rentTotalDay,
      outstandingAmt,
      outstandingDay,
      outstandingPercent,
      paidAmt,
      paidDay,
      paidPercent,
      overdueAmt,
      overdueDay,
      overduePercent,
      futureAmt,
      futureDay,
      futurePercent,
      dropAmt,
      dropDay,
      dropPercent,
      dataRentalTable,
      dataPopupDetail,

      // ประกาศตัวแปร Active / Inactive
      isPopupRegNumActive,
      isPopupDetailActive,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupRegNum,
      fncSearcRegNum,
      fncCancelRegNum,
      fncSelectRegNum,
      fncSubmit,
      fncPopupDetail,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
      resolveMonth,
    }
  },
}
</script>

<style lang="scss" scoped>
/* for local only */
// .delete-margin {
//   margin-bottom: -60px !important;
// }

// .delete-margin-report-summary {
//   margin-bottom: -40px !important;
// }
</style>
