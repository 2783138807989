import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    SP_CONFIG(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Master/SearchConfig', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_GET_CAR_REG(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('/RepaymentInquiry/GetCarReg', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_GET_CONTRACT_INQ(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('/RepaymentInquiry/GetContractInq', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_RENTAL_INQUIRY_1(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('/RepaymentInquiry/rentInq1', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_RENTAL_INQUIRY_2(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('/RepaymentInquiry/rentInq2', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_RENTAL_INQUIRY_3(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('/RepaymentInquiry/rentInq3', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
