<template>
  <b-modal
    ref="refModalDetail"
    centered
    hide-footer
    size="xl"
    title="รายละเอียดแยกตามรายวัน"
    @hidden="$emit('update:is-popup-detail-active', false)"
  >
    <b-card
      title="ประวัติการชำระเงิน"
      class="mb-1"
    >
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เลขทะเบียน"
            label-for="regNum1"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขทะเบียน
              </div>
            </template>
            <b-form-input
              id="regNum1"
              :value="dataPopupDetail.regNum1"
              placeholder="เลขทะเบียน"
              style="display: inline;width: 49%;"
              trim
              disabled
            />
            <b-form-input
              id="regNum2"
              :value="dataPopupDetail.regNum2"
              placeholder="เลขทะเบียน"
              style="display: inline;width: 50%;margin-left: 5px;"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อลูกค้า"
            label-for="customerName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อลูกค้า
              </div>
            </template>
            <b-form-input
              id="customerName"
              :value="dataPopupDetail.customerName"
              placeholder="ชื่อลูกค้า"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขตัวถัง"
            label-for="chassisNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขตัวถัง
              </div>
            </template>
            <b-form-input
              id="chassisNum"
              :value="dataPopupDetail.chassisNum"
              placeholder="หมายเลขตัวถัง"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เลขที่สัญญา"
            label-for="conNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขที่สัญญา
              </div>
            </template>
            <b-form-input
              id="conNum"
              :value="dataPopupDetail.conNum"
              placeholder="เลขที่สัญญา"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="พอร์ต"
            label-for="branchCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-form-input
              id="branchCode"
              :value="dataPopupDetail.branchCode"
              placeholder="พอร์ต"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อพอร์ต"
            label-for="branchName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อพอร์ต
              </div>
            </template>
            <b-form-input
              id="branchName"
              :value="dataPopupDetail.branchName"
              placeholder="ชื่อพอร์ต"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="สหกรณ์"
            label-for="salesMan"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สหกรณ์
              </div>
            </template>
            <b-form-input
              id="salesMan"
              :value="dataPopupDetail.salesMan"
              placeholder="สหกรณ์"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เซลล์แมน"
            label-for="salesMan"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เซลล์แมน
              </div>
            </template>
            <b-form-input
              id="salesMan"
              :value="dataPopupDetail.salesMan"
              placeholder="เซลล์แมน"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="dataRentalDetail.length"
      title="รายละเอียด"
      class="mb-1"
    >
      <b-row>
        <b-col cols="12">
          <b-table-simple
            caption-top
            bordered
            class="position-relative mb-1"
          >
            <b-tbody>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(0, 10)"
                  :key="index"
                  style="background-color: #e4e3e3"
                  class="text-center"
                >
                  <b>{{ item.date }} {{ `${resolveMonth[dataPopupDetail.rentMonth]} ${dataPopupDetail.rentYear}` }}</b>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(0, 10)"
                  :key="index"
                  class="text-center"
                >
                  {{ Number(item.rent_new_amt).toLocaleString() }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(0, 10)"
                  :key="index"
                  :style="`background-color:${resolveColor(item)}`"
                  class="text-center"
                >
                  {{ Number(item.rent_paid).toLocaleString() }}
                </b-td>
              </b-tr>

              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(10, 20)"
                  :key="index"
                  style="background-color: #e4e3e3"
                  class="text-center"
                >
                  <b>{{ item.date }} {{ `${resolveMonth[dataPopupDetail.rentMonth]} ${dataPopupDetail.rentYear}` }}</b>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(10, 20)"
                  :key="index"
                  class="text-center"
                >
                  {{ Number(item.rent_new_amt).toLocaleString() }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(10, 20)"
                  :key="index"
                  :style="`background-color:${resolveColor(item)}`"
                  class="text-center"
                >
                  {{ Number(item.rent_paid).toLocaleString() }}
                </b-td>
              </b-tr>

              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(20, 30)"
                  :key="index"
                  style="background-color: #e4e3e3"
                  class="text-center"
                >
                  <b>{{ item.date }} {{ `${resolveMonth[dataPopupDetail.rentMonth]} ${dataPopupDetail.rentYear}` }}</b>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(20, 30)"
                  :key="index"
                  class="text-center"
                >
                  {{ Number(item.rent_new_amt).toLocaleString() }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(20, 30)"
                  :key="index"
                  :style="`background-color:${resolveColor(item)}`"
                  class="text-center"
                >
                  {{ Number(item.rent_paid).toLocaleString() }}
                </b-td>
              </b-tr>

              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(30, 31)"
                  :key="index"
                  style="background-color: #e4e3e3"
                  class="text-center"
                >
                  <b>{{ item.date }} {{ `${resolveMonth[dataPopupDetail.rentMonth]} ${dataPopupDetail.rentYear}` }}</b>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(30, 31)"
                  :key="index"
                  class="text-center"
                >
                  {{ Number(item.rent_new_amt).toLocaleString() }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  v-for="(item, index) in dataRentalDetail.slice(30, 31)"
                  :key="index"
                  :style="`background-color:${resolveColor(item)}`"
                  class="text-center"
                >
                  {{ Number(item.rent_paid).toLocaleString() }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="ภาพรวมสรุป"
      class="mb-1"
    >
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="รวมยอดค่าเช่า"
            label-for="totalDue"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-form-input
              id="totalDue"
              :value="Number(totalDue).toLocaleString()"
              placeholder="รวมยอดค่าเช่า"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="รวมยอด Drop"
            label-for="totalDrop"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-form-input
              id="totalDrop"
              :value="Number(totalDrop).toLocaleString()"
              placeholder="รวมยอด Drop"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="รวมยอดชำระ"
            label-for="totalPaid"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-form-input
              id="totalPaid"
              :value="Number(totalPaid).toLocaleString()"
              placeholder="รวมยอดชำระ"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BTableSimple, BTbody, BTr, BTd,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
  },

  props: {
    dataPopupDetail: {
      type: [Object, Array],
      required: true,
    },

    isPopupDetailActive: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const APP_STORE_MODULE_NAME = 'appRepaymentInquiry'

    // ประกาศตัวแปร Ref
    const refModalDetail = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const dataRentalDetail = ref([])
    const totalDue = ref(null)
    const totalDrop = ref(null)
    const totalPaid = ref(null)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นปิด Popup */
    const fncHideModal = () => {
      refModalDetail.value.hide()
    }

    /* ฟังก์ชั่น Fetch Data */
    const fncFetchDataRental = (get_data = props.dataPopupDetail) => {
      const payload = {
        action: 'LIST',
        events: 'INQUIRY',
        function: 'GET',
        username: get_data.username,
        current_date: get_data.accesstime,
        regnum1_in: get_data.regNum1,
        regnum2_in: get_data.regNum2,
        chassis_number_in: get_data.chassisNum,
        rent_year: get_data.rentYear,
        rent_month: get_data.rentMonth,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_RENTAL_INQUIRY_3`, payload)
        .then(response => {
          if (response.data.responseData.length) {
            dataRentalDetail.value = []

            const getDaysInMonth = new Date(get_data.rentYear, get_data.rentMonth, 0).getDate()
            let i
            // eslint-disable-next-line no-plusplus
            for (i = 1; i <= getDaysInMonth; i++) {
              const setDay = String(i).padStart(2, 0)
              dataRentalDetail.value.push({
                date: i,
                rent_due_amt: response.data.responseData[0].[`rent_due_amt_${setDay}`],
                rent_new_amt: response.data.responseData[0].[`rent_new_amt_${setDay}`],
                rent_paid: response.data.responseData[0].[`rent_paid_${setDay}`],
              })

              totalDue.value = response.data.responseData[0].total_due_amt
              totalDrop.value = response.data.responseData[0].total_drop_amt
              totalPaid.value = response.data.responseData[0].total_paid
            }
          } else {
            dataRentalDetail.value = []
          }
        })
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    const resolveMonth = [
      '',
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ]

    const resolveColor = val => {
      if (val.rent_new_amt > val.rent_paid) return '#fbe1d8'
      // if(item.rent_due_amt == item.rent_new_amt == item.rent_paid) return '#BDDEBD'
      if (val.rent_due_amt !== val.rent_new_amt) return '#FFFDEA'
      return '#BDDEBD'
    }
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    watch(
      () => props.isPopupDetailActive,
      (first, second) => {
        if (first) {
          fncFetchDataRental()
          refModalDetail.value.show()
        } else if (second) {
          /* เมื่อมีการปิด Popup */
          fncHideModal()
        }
      },
    )

    return {
      // ประกาศตัวแปร Ref
      refModalDetail,

      // ประกาศตัวแปรสำหรับเก็บค่า
      dataRentalDetail,
      totalDue,
      totalDrop,
      totalPaid,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncHideModal,

      // ส่วนการทำงานแสดงผล UI
      resolveMonth,
      resolveColor,
    }
  },
}
</script>

<style lang="scss" scoped>
/* for local only */
// .delete-margin {
//   margin-bottom: -60px !important;
// }
</style>
